import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import * as vant from 'vant';
import 'vant/lib/index.css';
import i18n from './i18n/index';
import VueSweetalert2 from 'vue-sweetalert2';

import 'sweetalert2/dist/sweetalert2.min.css';
import { createPinia } from 'pinia';

const app = createApp(App);

for (const i in vant) {
  if (vant[i].install) app.use(vant[i]);
}
app.use(i18n);
app.use(router);
app.use(createPinia());
app.use(VueSweetalert2);

app.mount('#app');

console.log('env:', process.env.NODE_ENV, import.meta.env.VITE_GLOBAL_BASEURL);
