export default {
  // #region
  'i.yes': 'Yes',
  'i.no': 'No',
  'i.confirm': 'Confirm',
  'i.cancel': 'Cancel',
  'i.submit': 'Submit',
  'i.back': 'Back',
  'i.success': 'Successful',
  'i.fail': 'Failed',
  'i.add': 'Add',
  'i.create': 'Create',
  'i.edit': 'Edit',
  'i.updata': 'Upgrade',
  'i.del': 'Delete',
  'i.search': 'Search',
  'i.details': 'Detail',
  'i.export': 'Export',
  'i.reset': 'Reset',
  'i.save': 'Save',
  'i.default': 'Default',
  'i.register': 'Register',
  'i.login': 'Login',
  'i.operation': 'Operate',
  'i.saccount': 'Account',
  'i.password': 'Password',
  'i.email': 'Email',
  'i.phone': 'Telephone Number',
  'i.code': 'Code',
  'i.getCode': 'Get SMS',
  'i.gender': 'Gender',
  'i.age': 'Age',
  'i.state': 'Statu',
  'i.currency': 'Currency',
  'i.required': 'This field is required',
  'i.china': 'China',
  'i.resend': 'Resend',
  'i.name': 'Name',
  'i.time': 'Time',
  'i.price': 'Price',
  'i.type': 'Type',
  'i.start': 'Start',
  'i.end': 'End',
  'i.year': 'Year',
  'i.month': 'Month',
  'i.day': 'Day',
  'i.hour': 'Hour',
  'i.min': 'Minute',
  'i.sec': 'Second',
  'i.createTime': 'CreateTime',
  'i.updataTime': 'UpdataTime',
  'i.addDevice': 'Add device',
  'i.live': 'Live',
  'i.track': 'Track',
  'i.device': 'Device',
  'i.deviceList': 'Device list',
  'i.location': 'Location',
  'i.language': 'Language',
  'i.mapSource': 'Map source',
  'i.english': 'English',
  'i.chinese': 'Chinese',
  'i.spanish': 'Spanish',
  'i.mapSetting': 'Map Setting',
  'i.googleStreetmap': 'Google Street Map',
  'i.googleSatellitemap': 'Google Satellite Map',
  'i.baiduStreetmap': 'Baidu Street Map',
  'i.baiduSatellitemap': 'Baidu Satellite Map',
  'i.trackOfHistory': 'Track of history',
  'i.allHistory': 'All History',
  'i.totalTime': 'Total time',
  'i.totalMileage': 'Total Milleage',
  'i.playAll': 'Play All',
  'i.timeSpan': 'Time',
  'i.mileage': 'Mileage',
  'i.car': 'Car',
  'i.truck': 'Truck',
  'i.bike': 'Bike',
  'i.ship': 'Ship',
  'i.pickYourVehicle': 'Select Vehicle Model',
  'i.enterIMEINumber': 'Enter IMEI number',
  'i.selectDeviceType': 'Select device type',
  'i.enterSIMVardNumber': 'Enter SIM card number',
  'i.enterNotes': 'Enter notes...',
  'i.deviceType': 'device type',
  'i.notes': 'Note',
  'i.deviceName': 'Unit Name',
  'i.charactersLimit': 'From 4 to 50 characters',
  moving: 'Moving',
  stationary: 'Stationary',
  Online: 'Online',
  Offline: 'Offline',
  'i.coordinate': 'Coordinate',
  'i.duration': 'Duration',
  'i.timePeriod': 'Time period',
  'i.adddevice': 'Add Device',
  'i.editDevice': 'Edit',
  'i.bingStreetmap': 'Bing Street Map',
  'i.bingSatellitemap': 'Bing Satellite Map',
  'error.401': 'Current session has expired',
  'i.reLogin': 'relogin',
  'i.play': 'Play',
  'i.saveSuccess': 'Save Successful',
  'i.max5': 'You can choose only five devices at most',
  'i.top': 'Sticky on Top',
  'i.untop': 'Cancel Top',
  // #endregion

  // ToBeTranslated default start
  'i.loading': 'Loading...',
  // ToBeTranslated default end
};
