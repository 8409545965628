export default {
  // #region
  'i.yes': 'Sí',
  'i.no': 'No',
  'i.confirm': 'Confirmar',
  'i.cancel': 'Cancelar',
  'i.submit': 'Subir',
  'i.back': 'Volver',
  'i.success': 'Éxito',
  'i.fail': 'Fracaso',
  'i.add': 'Añadir',
  'i.create': 'Crear',
  'i.edit': 'Editar',
  'i.updata': 'Actualizar',
  'i.del': 'Borrar',
  'i.search': 'Consultar',
  'i.details': 'Detalle',
  'i.export': 'Exportar',
  'i.reset': 'Restablecer',
  'i.save': 'Conservar',
  'i.default': 'Por defecto',
  'i.register': 'Registrarse',
  'i.login': 'Iniciar sesión',
  'i.operation': 'Operar',
  'i.saccount': 'Cuenta',
  'i.password': 'Contraseña',
  'i.email': 'Correo electrónico',
  'i.phone': 'Número de teléfono',
  'i.code': 'Código',
  'i.getCode': 'Recibir SMS',
  'i.gender': 'Sexo',
  'i.age': 'Edad',
  'i.state': 'Estado',
  'i.currency': 'Moneda',
  'i.required': 'Este campo es obligatorio',
  'i.china': 'China',
  'i.resend': 'Reenviar',
  'i.name': 'Nombre',
  'i.time': 'Tiempo',
  'i.price': 'Precio',
  'i.type': 'Tipo',
  'i.start': 'Inicio',
  'i.end': 'Fin',
  'i.year': 'Año',
  'i.month': 'Mes',
  'i.day': 'Día',
  'i.hour': 'Hora',
  'i.min': 'Minuto',
  'i.sec': 'Segundo',
  'i.createTime': 'Crear tiempo',
  'i.updataTime': 'Modificar tiempo',
  'i.addDevice': 'Añadir dispositivo',
  'i.live': 'en vivo',
  'i.track': 'Seguimiento',
  'i.device': 'Dispositivo',
  'i.deviceList': 'Lista de dispositivos',
  'i.location': 'Ubicación',
  'i.language': 'Idioma',
  'i.mapSource': 'Fuente del mapa',
  'i.english': 'Inglés',
  'i.chinese': 'Chino',
  'i.spanish': 'Español',
  'i.mapSetting': 'Configuración del mapa',
  'i.googleStreetmap': 'Mapa de calles de google',
  'i.googleSatellitemap': 'Mapa de satélites de google',
  'i.baiduStreetmap': 'Mapa de calles de Baidu',
  'i.baiduSatellitemap': 'Mapa de satélites de Baidu',
  'i.trackOfHistory': 'Trayectoria histórica',
  'i.allHistory': 'Toda historia',
  'i.totalTime': 'Tiempo total',
  'i.totalMileage': 'Kilometraje total',
  'i.playAll': 'Reproducir todo',
  'i.timeSpan': 'Tiempo',
  'i.mileage': 'Kilometraje',
  'i.car': 'Coche',
  'i.truck': 'Camión',
  'i.bike': 'Bicicleta',
  'i.ship': 'Barco',
  'i.pickYourVehicle': 'Seleccionar coche',
  'i.enterIMEINumber': 'Entrar el número IMEI',
  'i.selectDeviceType': 'Elegir tipo de dispositivos',
  'i.enterSIMVardNumber': 'Entrar el número de tarjeta SIM',
  'i.enterNotes': 'Entrar notas',
  'i.deviceType': 'tipo de dispositivos',
  'i.notes': 'Nota',
  'i.deviceName': 'Nombre de equipamiento',
  'i.charactersLimit': 'De 4 a 50 caracteres',
  moving: 'Moviendo',
  stationary: 'Estática',
  Online: 'En línea',
  Offline: 'Fuera de línea',
  'i.coordinate': 'Coordenadas',
  'i.duration': 'Duración',
  'i.timePeriod': 'Duración',
  'i.adddevice': 'Añadir dispositivo',
  'i.editDevice': 'Editar',
  'i.bingStreetmap': 'Mapa de calles de Bing',
  'i.bingSatellitemap': 'Mapa de satélites de Bing',
  'error.401': 'La sesión actual ha expirado',
  'i.reLogin': 'volver a entrar',
  'i.play': 'Reproducir',
  'i.saveSuccess': 'Salvar con éxito',
  'i.max5': 'Sólo puedes elegir cinco dispositivos como máximo',
  'i.top': 'Siempre arriba',
  'i.untop': 'Cancelar Top',
  // #endregion

  // ToBeTranslated default start
  'i.loading': 'La carga...',
  // ToBeTranslated default end
};
