<template>
  <div class="fy h100p w100w">
    <router-view class="" v-slot="{ Component }">
      <component :is="Component" />
    </router-view>
  </div>
</template>

<script setup>
import { provide } from 'vue';
import * as __ from '@/utils/tool';
import * as i18n from '@/i18n/index';

const { setLang } = i18n;
const l = __.cookie.get('l');
const _l = __.storage.get('lang');
if (l) setLang(l);
else if (_l) setLang(_l);

setInterval(() => {
  const l = __.cookie.get('l');
  const language = i18n.getLang();
  if (!l || !language) return;
  if (l != language) {
    setLang(l);
    __.storage.set('lang', l);
  }
}, 100);

const el = document.getElementById('app');
el.style.height = window.innerHeight + 'px';
const fakeList = [
  {
    id: 1,
    type: 'car',
    state: 'Online',
    name: '737-Model…',
    time1: '2024-08-22 09:08:21',
    time2: 'To Now',
    time3: '12d 13h',
    speed: '36.9KM',
    run: 'Moving',
    location: 'Max Mustermann AG，MusterstraBe 131, 22525 Hamburg',
    show: true,
    track: [
      {
        trackId: 1,
        date: '2024/10/1',
        time1: '01:28',
        time2: '02:28',
        place1: 'Neon Cafe, 23/A Park Avenue',
        place2: 'Marquis street, Sector 32 West Northinghum',
        time: '00:15:10',
        mileage: '32.9KM',
        xy: '23.123456 123.123456',
        timePeriod: '2024-08-20 09:08:21 - 09:54:15',
      },
      {
        trackId: 2,
        date: '2024/10/1',
        time1: '01:08',
        time2: '02:08',
        place1: 'place1',
        place2: 'place2',
        time: '00:20:30',
        mileage: '2.9KM',
        xy: '223.123456 233.123456',
        timePeriod: '2024-08-22 09:08:21 - 09:54:15',
      },
      {
        trackId: 3,
        date: '2024/10/2',
        time1: '01:08',
        time2: '02:08',
        place1: 'place1',
        place2: 'place2',
        time: '00:40:30',
        mileage: '2.9KM',
        xy: '323.123456 231.123456',
        timePeriod: '2024-08-24 09:08:21 - 09:54:15',
      },
    ],
  },
  {
    id: 2,
    type: 'bigCar',
    state: 'Offline',
    name: 'FD63 VCW',
    time1: '2024-08-22 09:08:21',
    time2: 'To Now',
    time3: '12d 13h',
    speed: '136.9KM',
    run: 'Moving',
    location: 'Max Mustermann AG，MusterstraBe 131, 22525 Hamburg',
    show: true,
    track: [
      {
        trackId: 1,
        date: '2024/10/1',
        time1: '01:08',
        time2: '02:08',
        place1: 'place123',
        place2: 'place321',
        time: '00:00:30',
        mileage: '9KM',
        xy: '123.123456 23.123456',
        timePeriod: '2024-08-25 09:08:21 - 09:54:15',
      },
    ],
  },
  {
    id: 3,
    type: 'boat',
    state: 'Offline',
    name: 'G1C-Model Y',
    time1: '2024-08-22 09:08:21',
    time2: 'To Now',
    time3: '12d 13h',
    speed: '326.9KM',
    run: 'Moving',
    location: 'Max Mustermann AG，MusterstraBe 131, 22525 Hamburg',
    show: true,
    track: [
      {
        trackId: 1,
        date: '2024/10/1',
        time1: '01:08',
        time2: '02:08',
        place1: 'place111',
        place2: 'place222',
        time: '00:40:30',
        mileage: '2KM',
        xy: '123.123456 23.123456',
        timePeriod: '2024-08-26 09:08:21 - 09:54:15',
      },
    ],
  },
];

provide('fakeList', fakeList);
</script>

<style lang="less">
@import url('./style/style_index.css');
@import url('./style/styles_less.less');

.main-c {
  color: #14c6a4;
}
.main-b {
  background: #14c6a4;
}
.van-calendar__day {
  opacity: 0.5;
}
.o10i {
  opacity: 1 !important;
  font-weight: 600;
}
.cell-gray {
  background: #ededed !important;
}
</style>
