import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/live',
    name: 'live',
    component: () => import('@/views/live/index.vue'),
  },
  {
    path: '/track',
    name: 'track',
    component: () => import('@/views/track/index.vue'),
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/device/index.vue'),
  },
  {
    path: '/liveAddDevice',
    name: 'liveAddDevice',
    component: () => import('@/views/liveAddDevice/index.vue'),
  },
  {
    path: '/settle',
    name: 'settle',
    component: () => import('@/views/settle/index.vue'),
  },
  {
    path: '/mapSource',
    name: 'mapSource',
    component: () => import('@/views/mapSource/index.vue'),
  },
  {
    path: '/editDevice',
    name: 'editDevice',
    component: () => import('@/views/editDevice/index.vue'),
  },
  {
    path: '/toTrack',
    name: 'toTrack',
    component: () => import('@/views/toTrack/index.vue'),
  },
  {
    path: '/language',
    name: 'language',
    component: () => import('@/views/language/index.vue'),
  },
  {
    path: '/(*)',
    redirect: '/',
  },
  {
    path: '',
    redirect: '/live',
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
