export default {
  // #region
  'i.yes': '是',
  'i.no': '否',
  'i.confirm': '确定',
  'i.cancel': '取消',
  'i.submit': '提交',
  'i.back': '返回',
  'i.success': '成功',
  'i.fail': '失败',
  'i.add': '添加',
  'i.create': '新建',
  'i.edit': '编辑',
  'i.updata': '更新',
  'i.del': '删除',
  'i.search': '查询',
  'i.details': '详情',
  'i.export': '导出',
  'i.reset': '重置',
  'i.save': '保存',
  'i.default': '默认',
  'i.register': '注册',
  'i.login': '登录',
  'i.operation': '操作',
  'i.saccount': '账号',
  'i.password': '密码',
  'i.email': '邮箱',
  'i.phone': '手机号',
  'i.code': '验证码',
  'i.getCode': '获取验证码',
  'i.gender': '性别',
  'i.age': '年龄',
  'i.state': '状态',
  'i.currency': '币种',
  'i.required': '此项必填',
  'i.china': '中国',
  'i.resend': '重新发送',
  'i.name': '名称',
  'i.time': '时间',
  'i.price': '价格',
  'i.type': '类型',
  'i.start': '开始',
  'i.end': '结束',
  'i.year': '年',
  'i.month': '月',
  'i.day': '日',
  'i.hour': '时',
  'i.min': '分',
  'i.sec': '秒',
  'i.createTime': '创建时间',
  'i.updataTime': '修改时间',
  'i.addDevice': '添加设备',
  'i.live': '实时',
  'i.track': '轨迹',
  'i.device': '设备',
  'i.deviceList': '设备列表',
  'i.location': '位置',
  'i.language': '语言',
  'i.mapSource': '地图来源',
  'i.english': '英语',
  'i.chinese': '中国',
  'i.spanish': '西班牙语',
  'i.mapSetting': '地图设置',
  'i.googleStreetmap': '谷歌街道地图',
  'i.googleSatellitemap': '谷歌卫星地图',
  'i.baiduStreetmap': '百度街道地图',
  'i.baiduSatellitemap': '百度卫星地图',
  'i.trackOfHistory': '历史轨迹',
  'i.allHistory': '所有历史',
  'i.totalTime': '总时间',
  'i.totalMileage': '总里程',
  'i.playAll': '播放全部',
  'i.timeSpan': '时间',
  'i.mileage': '里程',
  'i.car': '汽车',
  'i.truck': '卡车',
  'i.bike': '自行车',
  'i.ship': '船',
  'i.pickYourVehicle': '选择你的车辆',
  'i.enterIMEINumber': '输入IMEI号码',
  'i.selectDeviceType': '选择设备类型',
  'i.enterSIMVardNumber': '输入SIM卡号',
  'i.enterNotes': '输入备注……',
  'i.deviceType': '设备类型',
  'i.notes': '备注',
  'i.deviceName': '设备名称',
  'i.charactersLimit': '4到50个字',
  moving: '移动中',
  stationary: '静止',
  Online: '在线',
  Offline: '离线',
  'i.coordinate': '坐标',
  'i.duration': '持续时间',
  'i.timePeriod': '时间',
  'i.adddevice': '添加设备',

  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.spanish': 'Spanish',
  'i.editDevice': '编辑设备',
  'i.bingStreetmap': 'Bing街道地图',
  'i.bingSatellitemap': 'Bing卫星地图',
  'error.401': '当前会话已过期',
  'i.reLogin': '重新登陆',
  'i.play': '播放',
  'i.saveSuccess': '保存成功',
  'i.max5': '您最多只能选择5个设备',
  'i.top': '置顶',
  'i.untop': '取消置顶',
  // #endregion

  // ToBeTranslated default start
  'i.loading': '加载中...',
  // ToBeTranslated default end
};
